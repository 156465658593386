import { RouteObject, useRoutes } from "react-router-dom";
import { publicRoutes } from "./public";
import { protectedRoutes } from "./protected";
/**
 *
 * @returns Routes Elements
 */

const AppRoutes:any = () => {
  const mainRoutes: RouteObject[] = protectedRoutes;
  const element = useRoutes([...mainRoutes]);
  const elementPublic = useRoutes([...publicRoutes]);
  return (
    <>
      {element}
      {elementPublic}
    </>
  );
}

export default AppRoutes;
