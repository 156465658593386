import { RouteObject } from "react-router-dom";
import { Login } from "../pages/auth/login";
import { SetAccountPassword } from "../pages/auth/set-account";
import { ResetPassword } from "../pages/auth/reset-password";
import { ForgotPassword } from "../pages/auth/forgot-password";

/**
 * Public Routes Available in App
 * @type {{}}
 */

export const publicRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "/set-account",
    element: <SetAccountPassword />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password/:token",
    element: <ResetPassword />,
  },
];
