import { Col, Row, Stack } from 'react-bootstrap';
import { FaMoneyBillWave } from 'react-icons/fa';
import { SvgIcons } from '../../../components/common/SvgIcons';
import { FC, useEffect, useState } from 'react';
interface IBillingStatus {
    dashboardData?: any
}

const BillingStatus: FC<IBillingStatus> = ({
    dashboardData
}) => {
    const [billingData, setBillingData] = useState<any>([]);

    useEffect(() => {
        if (dashboardData) {
            let filteredValueInExcessDays: any = {};
            let filteredValueAccountStatus: any = {};
            let filteredValueFinancialObligation: any = {};
            let finalArr: any = [];
            let dashboardDataPPRecords = dashboardData?.billingPPDetails ? dashboardData?.billingPPDetails : [];
            if (dashboardDataPPRecords?.length > 0) {
                const newFilteredArray = dashboardDataPPRecords?.filter((element: any) => element?.custom_field_ref?.label?.toLowerCase() == "default billing account in excess of days" || element?.custom_field_ref?.label?.toLowerCase() == "billing account status" || element?.custom_field_ref?.label?.toLowerCase() == "progress of successful financial obligation")

                if (newFilteredArray && newFilteredArray.length > 0) {
                    for (const elementFilter of newFilteredArray) {
                        if (elementFilter?.custom_field_ref?.label?.toLowerCase() == "default billing account in excess of days") {
                            filteredValueInExcessDays = elementFilter;
                        } else if (elementFilter?.custom_field_ref?.label?.toLowerCase() == "billing account status") {
                            filteredValueAccountStatus = elementFilter;
                        } else if (elementFilter?.custom_field_ref?.label?.toLowerCase() == "progress of successful financial obligation") {
                            filteredValueFinancialObligation = elementFilter;
                        }
                    }
                }
                if (newFilteredArray?.length > 0 && filteredValueAccountStatus && filteredValueFinancialObligation && filteredValueInExcessDays) {
                    finalArr[0] = filteredValueAccountStatus;
                    finalArr[1] = filteredValueFinancialObligation;
                    finalArr[2] = filteredValueInExcessDays;
                }
                setBillingData(finalArr);
            }
        }
    }, [dashboardData])

    return (
        <Row className="g-3 g-xl-4">
            {
                billingData?.length > 0 ?
                    billingData.map((cardData: any) => {
                        const { custom_field_ref, value_string } = cardData;
                        let bgColor = "alert-success";
                        let dataIcon = SvgIcons.billingIcon;
                        let textColor = "text-success";
                        let subTitle = "Billing Account Status";
                        if (custom_field_ref?.label?.toLowerCase() == "progress of successful financial obligation") {
                            subTitle = "Progress of Payment";
                            bgColor = "alert-default";
                            dataIcon = SvgIcons.dashboardPaymentIcon;
                            textColor = "text-secondary";
                        } else if (custom_field_ref?.label?.toLowerCase() == "default billing account in excess of days") {
                            subTitle = "Installment Overdue";
                            bgColor = "alert-danger";
                            dataIcon = <FaMoneyBillWave size={32} />;
                            textColor = "text-danger";
                        }
                        return (
                            <Col
                                key={custom_field_ref?.id}
                                md={6}
                                lg={4}
                                className="mw-1 text-wrap"
                            >
                                <Stack
                                    direction="horizontal"
                                    gap={3}
                                    className={`alert border-0 gap-3 h-100 hstack m-0 px-3 py-1 w-100 rounded-1 ${bgColor}`}
                                >
                                    <Stack
                                        direction="vertical"
                                        className="me-auto text-body fw-semibold dashboard-card-box"
                                    >
                                        <h5 className="mb-0 fw-semibold">{value_string}</h5>
                                        <small>{subTitle}</small>
                                    </Stack>
                                    <span>
                                        {dataIcon}
                                    </span>
                                </Stack>
                            </Col>
                        )
                    })
                    :
                    ''
            }
        </Row>
    )
}

export default BillingStatus