import { ThunkDispatch } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ThemeCustomDropdown from "../../components/common/CustomDropdown";
import Loader from "../../components/common/Loader";
import { dashboarActions, dashboardCombineAction } from "../../redux/dashboardModule/dashboardSlice";
import { getCaseOfClientBasedOnClientIDAction, questionairreActions } from "../../redux/questionairreModule/questionairreSlice";
import "./dashboard.scss";
import BillingStatus from "./sections/BillingStatus";
import Documents from "./sections/Documents";
import EventAlert from "./sections/EventAlert";
import Questionnaires from "./sections/Questionnaires";

const Dashboard = () => {
    const caseIDGlobal = useSelector((state: any) => state.questionairreSlice.caseID);
    const caseID = useSelector((state: any) => state.dashboardSlice.caseID);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const isLoading = useSelector((state: any) => state.dashboardSlice.loading);
    const [caseOptions, setCaseOptions] = useState<any>(caseIDGlobal ? caseIDGlobal : caseID);
    const [caseOptionsData, setCaseOptionsData]: any = useState<any>([]);
    const [caseNumber, setCaseNumber] = useState<any>("");
    const [dashboardData, setDashboardData] = useState<any>("");

    useEffect(() => {
        GetCasesForClient();
    }, [])

    const dashboardApiData:any = (caseId: any) => {
        const tempCaseOptionsBlank = caseId ? true : false;
        getDashboardData({ case_id: caseId }, tempCaseOptionsBlank);
    }

    const getDashboardData = (payload: any, caseOptionValue: boolean) => {
        dispatch(dashboardCombineAction(payload)).then((data: any) => {
            if (data?.payload?.statusCode == 200) {
                setDashboardData(data?.payload)
                if (!caseOptionValue) {
                    setCaseOptions(caseIDGlobal ? caseIDGlobal : data?.payload?.caseId)
                    dispatch(questionairreActions.setCaseID({ caseID: caseIDGlobal ? caseIDGlobal : data?.payload?.caseId }));
                }
            }
        }).catch((err: any) => {
            console.log(err);
        });
    };

    const GetCasesForClient = () => {
        dispatch(getCaseOfClientBasedOnClientIDAction()).then(data => {
            if (data.payload.statusCode == 200) {
                let cases: any = []
                data.payload.data.forEach((element: { file_number: any; id: any; case_id_number: any }) => {
                    cases.push({
                        label: element.file_number, value: element.id, case_id_number: element.case_id_number
                    });
                });
                setCaseOptionsData(cases);
                setCaseOptions(caseIDGlobal ? caseIDGlobal : caseID ?? cases[0].id);
                setCaseNumber(caseNumber !== "" ? caseNumber : cases[0].case_id_number)
                dashboardApiData(caseIDGlobal ? caseIDGlobal : '');
            }
        })
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
                {/* Header */}
                <Stack
                    direction="horizontal"
                    gap={2}
                    className="flex-wrap mb-2"
                >
                    <h1 className="fs-4 fw-semibold mb-0 me-auto">Dashboard</h1>
                    <Stack
                        direction="horizontal"
                        gap={1}
                    >
                        <span className="small">Switch Case:</span>
                        <div className="w-250">
                            <ThemeCustomDropdown
                                controlId="case_id"
                                placeHolder="Select"
                                data={caseOptionsData}
                                value={caseOptions}
                                handleFilter={(e: any) => {
                                    setCaseNumber(caseOptionsData.find((i: { value: any; }) => {
                                        return i.value === e.value
                                    }).case_id_number)
                                    dispatch(questionairreActions.setCaseID({ caseID: e.value }));
                                    setCaseOptions(e.value);
                                    dashboardApiData(e.value)
                                }}
                            />
                        </div>
                    </Stack>
                </Stack>

                <Row className="gx-3 py-2">
                    {/* Billing Status Section */}
                    <Col xs={12} className="mb-2 pb-1">
                        <BillingStatus dashboardData={dashboardData} />
                    </Col>

                    {/* Questionnaires Section */}
                    <Col sm={6} className="mb-3 pb-1">
                        <Questionnaires dashboardData={dashboardData} />
                    </Col>

                    {/* Documents Section */}
                    <Col sm={6} className="mb-3 pb-1">
                        <Documents dashboardData={dashboardData} />
                    </Col>

                    {/* Event Alert Section */}
                    <Col xs={12} className="mb-3">
                        <EventAlert dashboardData={dashboardData} />
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default Dashboard;