export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const IO_IOS_SEARCH_SIZE = 21;

export const POP_UP_CLOSE_1000 = 2000;

export const POP_UP_CLOSE_2000 = 2000;

export const POP_UP_CLOSE_5000 = 5000;

export const POP_UP_CLOSE_10000 = 10000;

export const DEF_SORT_ORDER = "DESC";
export const LIST_CRR_PAGE = 1;
export const ROLES_DEFAULT_SORT_COLUMN = "updated_at";
export const REACT_TABLE_ORDER = "DSC";
export const HIDE_TABLE_PAGINATOR = true;
export const CUSTOM_PAGINATOR_VALUES = [10, 20, 30];
export const PAGINATOR_ROWS_DEF = 10;
export const PAGINATOR_FIRST = 0;
export const DEF_INC_COUNT = 1;

export const EDIT = "edit";
export const VIEW = "view";
export const ADD = "add";

export const LOGIN_OTHER_BROWSER = "It seems you're logged into other browser tab or window.";

// User Management Add User Department Options
export const SUCCESS_STATUS = 200;
export const SUCCESS_STATUS_ERROR = 201;

export const DOB_FORMAT = "MM-DD-YYYY";
export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_FORMAT_EMAIL_REPLY = "MM/DD/YYYY HH:mm:ss";

//Delete Modal Content
export const DELETE_DOCUMENT_TITLE = "Delete Document";
export const DELETE_DOCUMENT_CONTENT = "Are you sure, you want to delete the Document?";
export const DELETE_NOTE_TITLE = "Delete Note";
export const DELETE_NOTE_CONTENT = "Are you sure, you want to delete the Note permanently?";

export const ALLOWEDFILETYPES = "Allowed file types are";
export const ALLOWEDFILETYPEPDF = "Only PDF files are allowed for forms.";
export const FILE_NOT_UPLOADED = "File not uploaded on sharepoint. Please try again.";
export const MAX_ALLOWED_FILES_SIZE_ALLOWED = "It seems that one of your file exceed maximum size of 20 MB.";
export const MAX_SELECT_LABEL_LEGTH = 3;
export const IS_MULTI_SELECT_FILTER = true;